export class ApiResponse {
    constructor() {
        this.successful = false
        this.authenticated = false
    }

    static Create(response: any) {
        var result = new ApiResponse();
        result.authenticated = true

        var ok = response.status >= 200 || response.status < 300;

        if (!ok) {
            result.successful = false
        } else {
            result.successful = true
            try {
                result.data = response.data;
            } catch (ex) {
                console.log(ex)
                console.log('No content for this request')
            }
        }

        return result
    }

    successful: Boolean
    validationErrors: any
    authenticated: Boolean
    data: any
}

export class ListQueryParams {
    constructor() {
        this.skip = 0
        this.take = 50
        this.direction = 'ascending'
        this.params = Array<QueryParameter>()
    }

    Sort(sort: string, direction?: string) {
        this.sort = sort

        if (direction && direction === 'desc') return this.Descending()
        if (direction && direction !== 'desc') return this.Ascending()

        return this
    }

    Descending() {
        this.direction = 'descending'
        return this
    }

    Ascending() {
        this.direction = 'ascending'
        return this
    }

    Paginate(skip: number, take: number) {
        this.skip = skip
        this.take = take
        return this
    }

    Search(value: string) {
        this.WithParam('q', value)
        return this
    }

    WithStart(value: any) {
        this.WithParam('start', value)
        return this
    }

    WithEnd(value: any) {
        this.WithParam('end', value)
        return this
    }

    WithParam(param: string, value: any) {
        this.params.push({
            key: param,
            value: value,
        })

        return this
    }

    skip: number
    take: number
    direction: string
    params: QueryParameter[]
    sort?: string

    GenerateQueryString() {
        var query = '?skip=' + this.skip + '&take=' + this.take
        if (this.sort) query = query + '&sort=' + this.sort + '&direction=' + this.direction

        if (this.params) {
            this.params.map((param) => {
                if (param.value && param.key) {
                    if (param.value instanceof Date) {
                        query = query + '&' + param.key + '=' + (<Date>param.value).toISOString()
                    } else {
                        query = query + '&' + param.key + '=' + param.value
                    }
                } else if (param.value !== null && param.value !== undefined) {
                    query = query + '&' + param.key + '=' + param.value
                }
            })
        }

        return query
    }
}

export class QueryParameter {
    key?: string
    value?: any
}
