import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class TenantsQueryParams extends ListQueryParams {
    WithEnabled(value: boolean) {
        this.WithParam('active', value)
        this.WithParam('enabled', value)
        return this
    }
    WithTenantTypeIds(value: string[]) {
        this.WithParam('tenantTypeIds', value.toString())
        return this
    }

    WithManagedOthers(value: boolean) {
        this.WithParam('manageOthers', value)
        return this
    }

    WithTenantManagerId(value: boolean) {
        this.WithParam('tenantManagerId', value)
        return this
    }
    WithRemoveClients(value: any) {
        this.WithParam('removeClients', value)
        return this
    }
    WithShowOCOnly(value: boolean) {
        this.WithParam('showOCOnly', value)
        return this
    }
    WithRemoveOCContractors(value: boolean) {
        this.WithParam('removeOCContractors', value)
        return this
    }
    WithRemoveContractors(value: boolean) {
        this.WithParam('removeContractors', value)
        return this
    }
    WithClientWOUpdateUserId(value: string) {
        this.WithParam('clientWOUpdateUserId', value)
        return this
    }
    WithPopulateTenantMappings(value: boolean) {
        this.WithParam('populateTenantMappings', value)
        return this
    }
}

export class TenantsClient {
    static async List(params: TenantsQueryParams) {
        return await ApiClient.Get('/tenants' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/tenants/' + id);
    }

    static async GetManagers(id: string) {
        return await ApiClient.Get('/tenants/' + id + '/managers');
    }

    static async GetTenantTypeByName(params: TenantsQueryParams) {
        return await ApiClient.Get('/tenant-types' + params.GenerateQueryString());
    }

    static async BulkCreate(file: any, businessTypeId: string) {
        let formData = new FormData()
        formData.append('file', file)
        formData.append('businessTypeId', businessTypeId)

        var accessToken = localStorage.getItem('acess-token')

        const response = await fetch((await GetAPIDomain()) + '/tenants/bulk', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        return await response.json()
    }

    static async Create(
        name: string,
        address1: string,
        address2: string,
        address3: string,
        address4: string,
        address5: string,
        address6: string,
        country: string,
        tenantTypeId: string,
        businessTypeId: string,
        active: boolean,
        manageOthers: boolean,
        adminFee: any,
        percentageFee: string | null,
        thresholdMoney: string | null,
        autoApproveMinor: boolean,
        dataProvider: string,
        externalId: string | null,
        externalProjectId: string | null,
        adminFeeChargeTypeId: string | null,
        percentageFeeChargeTypeId: string | null,
        defaultChargeTypeId: string | null,
        themeColor: string,
        applyAdminFeeWhenOverThreshold: boolean,
        suppliedPurchaseOrder: string | null,
        quoteStatusFlowId: string | null
    ) {
        return await ApiClient.Post('/tenants', {
            name,
            address1,
            address2,
            address3,
            address4,
            address5,
            address6,
            country,
            tenantTypeId,
            businessTypeId,
            active,
            manageOthers,
            adminFee,
            percentageFee,
            thresholdMoney,
            autoApproveMinor,
            dataProvider,
            externalId,
            externalProjectId,
            adminFeeChargeTypeId,
            percentageFeeChargeTypeId,
            defaultChargeTypeId,
            themeColor,
            applyAdminFeeWhenOverThreshold,
            suppliedPurchaseOrder,
            quoteStatusFlowId
        });
    }


    static async UpdateProperty(
        id: string,
        property: string,
        value: any
    ) {
        return await ApiClient.Patch('/tenants/' + id, [
            {
                op: 'replace',
                path: property,
                value: value,
            }
        ]);
    }


    static async SetMappedTypes(
        id: string,
        typeIds: any[]
    ) {
        return await ApiClient.Post('/tenants/' + id + '/work-order-types', {
            typeIds
        });
    }


    static async SetMappedCategories(
        id: string,
        categoryIds: any[]
    ) {
        return await ApiClient.Post('/tenants/' + id + '/work-order-categories', {
            categoryIds
        });
    }

    static async Update(
        id: string,
        name: string,
        address1: string,
        address2: string,
        address3: string,
        address4: string,
        address5: string,
        address6: string,
        country: string,
        tenantTypeId: string,
        businessTypeId: string,
        active: boolean,
        manageOthers: boolean,
        adminFee: any,
        percentageFee: string | null,
        thresholdMoney: string | null,
        autoApproveMinor: boolean,
        dataProvider: string,
        externalId: string | null,
        externalProjectId: string | null,
        adminFeeChargeTypeId: string | null,
        percentageFeeChargeTypeId: string | null,
        defaultChargeTypeId: string | null,
        themeColor: string,
        applyAdminFeeWhenOverThreshold: boolean,
        suppliedPurchaseOrder: string | null,
        quoteStatusFlowId: string | null
    ) {
        return await ApiClient.Patch('/tenants/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'address1',
                value: address1,
            },
            {
                op: 'replace',
                path: 'address2',
                value: address2,
            },
            {
                op: 'replace',
                path: 'address3',
                value: address3,
            },
            {
                op: 'replace',
                path: 'address4',
                value: address4,
            },
            {
                op: 'replace',
                path: 'address5',
                value: address5,
            },
            {
                op: 'replace',
                path: 'address6',
                value: address6,
            },
            {
                op: 'replace',
                path: 'country',
                value: country,
            },
            {
                op: 'replace',
                path: 'tenantTypeId',
                value: tenantTypeId,
            },
            {
                op: 'replace',
                path: 'businessTypeId',
                value: businessTypeId,
            },
            {
                op: 'replace',
                path: 'active',
                value: active,
            },
            {
                op: 'replace',
                path: 'manageOthers',
                value: manageOthers,
            },
            {
                op: 'replace',
                path: 'adminFee',
                value: adminFee,
            },
            {
                op: 'replace',
                path: 'percentageFee',
                value: percentageFee,
            },
            {
                op: 'replace',
                path: 'thresholdMoney',
                value: thresholdMoney,
            },
            {
                op: 'replace',
                path: 'autoApproveMinor',
                value: autoApproveMinor,
            },
            {
                op: 'replace',
                path: 'dataProvider',
                value: dataProvider,
            },
            {
                op: 'replace',
                path: 'externalId',
                value: externalId,
            },
            {
                op: 'replace',
                path: 'externalProjectId',
                value: externalProjectId,
            },
            {
                op: 'replace',
                path: 'adminFeeChargeTypeId',
                value: adminFeeChargeTypeId,
            },
            {
                op: 'replace',
                path: 'percentageFeeChargeTypeId',
                value: percentageFeeChargeTypeId,
            },
            {
                op: 'replace',
                path: 'defaultChargeTypeId',
                value: defaultChargeTypeId,
            },
            {
                op: 'replace',
                path: 'themeColor',
                value: themeColor,
            },
            {
                op: 'replace',
                path: 'applyAdminFeeWhenOverThreshold',
                value: applyAdminFeeWhenOverThreshold,
            },
            {
                op: 'replace',
                path: 'suppliedPurchaseOrder',
                value: suppliedPurchaseOrder,
            },
            {
                op: 'replace',
                path: 'quoteStatusFlowId',
                value: quoteStatusFlowId,
            }
        ]);
    }

    static async UploadLogo(tenantId: string, file: any) {
        let formData = new FormData()
        formData.append('file', file)

        var accessToken = localStorage.getItem('acess-token')

        const response = await fetch((await GetAPIDomain()) + '/tenants/' + tenantId + '/upload-logo', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        if (!response.ok) {
            return false
        }

        await response.json()
    }

    static async DeleteLogo(tenantId: string) {
        return await ApiClient.Delete('/tenants/' + tenantId + '/remove-logo');
    }
}
