/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Dispatch, FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  MixedWidget2,
  MixedWidget10,
  MixedWidget11,
  ListsWidget2,
  ListsWidget3,
  ListsWidget4,
  ListsWidget5,
  ListsWidget6,
  TablesWidget5,
  TablesWidget10,
  MixedWidget8,
} from '../../../_metronic/partials/widgets'

import WorkOrderStatusView from './widgets/WorkOrderStatusView'
import ActiveWorkOrdersByCompany from './widgets/ActiveWorkOrdersByCompany'
import CheckInTimeline from './widgets/CheckInTimeline'
import CheckInsRealtime from './widgets/CheckInsRealtime'
import TenantSelector from '../../common/components/selectors/TenantSelector'
import {shallowEqual, useSelector, connect} from 'react-redux'
import {RootState} from '../../../setup'
import {TenantTypeIds} from '../../common/constants/tenantTypeIds'
import {Actions} from './redux/DashboardRedux'
import WorkOrderDueDateConflict from './widgets/WorkOrderDueDateConflict'

type Props = {
  dashboard: any
  dispatch: Dispatch<any>
}

const DashboardWrapper: React.FC<Props> = ({dashboard, dispatch}) => {
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const [defaultTenant, setDefaultTenant] = useState<any>({
    value: user.tenantId,
    label: user.tenant?.name,
  })

  useEffect(() => {
    if (dashboard.filter.tenantId) {
      setDefaultTenant({
        value: dashboard.filter.tenantId,
        label: dashboard.filter.tenantName,
      })
    } else {

        if (user.tenant?.tenantTypeId?.toUpperCase() === TenantTypeIds.Owner) {
            dispatch(
                Actions.setFilter({
                    ...dashboard.filter,
                    tenantId: 'all',
                    tenantName: 'All',
                }))
        } else {
            dispatch(
                Actions.setFilter({
                    ...dashboard.filter,
                    tenantId: user.tenantId,
                    tenantName: user.tenant?.name,
                }))
        }

    }
  }, [])

  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <>
        {/* begin::Row */}
        <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
          <div className='col-xxl-4'>
            <label className='form-label'>Business</label>
            <TenantSelector
              placeholder='Select Business'
              defaultValue={defaultTenant}
              onChange={(name: any, value: any, label: any) => {
                dispatch(
                  Actions.setFilter({...dashboard.filter, tenantId: value, tenantName: label})
                )
              }}
              id='tenantId'
              includeAll
              disabled={
                user.tenant?.tenantTypeId !== TenantTypeIds.Owner.toLowerCase() ? true : false
              }
            />
          </div>
        </div>

        <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
          <div className='col-xxl-12'>
            <CheckInsRealtime tenantId={dashboard.filter.tenantId} />
          </div>
        </div>
        <div className='row gy-5 g-xl-8 mb-5 mb-xl-8'>
          <div className='col-xxl-4'>
            <WorkOrderDueDateConflict tenantId={dashboard.filter.tenantId} />
            {/* <CheckInTimeline tenantId={dashboard.filter.tenantId} /> */}
          </div>
          <div className='col-xxl-4'>
            <ActiveWorkOrdersByCompany tenantId={dashboard.filter.tenantId} />
          </div>
          <div className='col-xxl-4'>
            <WorkOrderStatusView tenantId={dashboard.filter.tenantId} />
          </div>
        </div>

        <div className='row gy-5 g-xl-8' style={{display: 'none'}}>
          <div className='col-xxl-4'>
            <MixedWidget2
              className='card-xl-stretch mb-xl-8'
              chartColor='danger'
              chartHeight='200px'
              strokeColor='#cb1e46'
            />
          </div>
          <div className='col-xxl-4'>
            <ListsWidget5 className='card-xxl-stretch' />
          </div>
          <div className='col-xxl-4'>
            <MixedWidget10
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='primary'
              chartHeight='150px'
            />
            <MixedWidget11
              className='card-xxl-stretch-50 mb-5 mb-xl-8'
              chartColor='primary'
              chartHeight='175px'
            />
          </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 gx-xl-8' style={{display: 'none'}}>
          <div className='col-xxl-4'>
            <ListsWidget3 className='card-xxl-stretch mb-xl-3' />
          </div>
          <div className='col-xl-8'>
            <TablesWidget10 className='card-xxl-stretch mb-5 mb-xl-8' />
          </div>
        </div>
        {/* end::Row */}

        {/* begin::Row */}
        <div className='row gy-5 g-xl-8' style={{display: 'none'}}>
          <div className='col-xl-4'>
            <ListsWidget2 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-4'>
            <ListsWidget6 className='card-xl-stretch mb-xl-8' />
          </div>
          <div className='col-xl-4'>
            <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-8' items={5} />
            {/* partials/widgets/lists/_widget-4', 'class' => 'card-xl-stretch mb-5 mb-xl-8', 'items' => '5' */}
          </div>
        </div>
        {/* end::Row */}

        <div className='row g-5 gx-xxl-8' style={{display: 'none'}}>
          <div className='col-xxl-4'>
            <MixedWidget8
              className='card-xxl-stretch mb-xl-3'
              chartColor='success'
              chartHeight='150px'
            />
          </div>
          <div className='col-xxl-8'>
            <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
          </div>
        </div>
      </>
    </>
  )
}

export default connect(({dashboard}: RootState) => ({
  dashboard,
}))(DashboardWrapper)
