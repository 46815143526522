import { ApiClient } from '../../../setup/axios/SetupAxios'
import { ListQueryParams } from '../ApiClient'
import { GetAPIDomain } from '../Helper'

export class AttachmentsQueryParams extends ListQueryParams {
    WithWorkOrderId(value: any) {
        this.WithParam('workOrderId', value)
        return this
    }
    WithQuoteId(value: any) {
        this.WithParam('quoteId', value)
        return this
    }
    WithReviewId(value: any) {
        this.WithParam('reviewId', value)
        return this
    }
    WithProposalId(value: any) {
        this.WithParam('proposalId', value)
        return this
    }
    WithWorkSafeOnly(value: boolean) {
        this.WithParam('workSafeOnly', value)
        return this
    }
    WithCheckinId(value: string) {
        this.WithParam('checkInId', value)
        return this
    }
    WithAssetId(value: string) {
        this.WithParam('assetId', value)
        return this
    }
    WithContractId(value: string) {
        this.WithParam('contractId', value)
        return this
    }
    WithHazardId(value: string) {
        this.WithParam('hazardId', value)
        return this
    }
    WithIncidentId(value: string) {
        this.WithParam('incidentId', value)
        return this
    }
    WithIncidentActionId(value: string) {
        this.WithParam('incidentActionId', value)
        return this
    }

    WithImagesOnly(value: boolean) {
        this.WithParam('imagesOnly', value)
        return this
    }
}

export class AttachmentsClient {
    static async List(params: AttachmentsQueryParams) {
        return await ApiClient.Get('/attachments' + params.GenerateQueryString());
    }

    static async Get(id: string) {
        return await ApiClient.Get('/attachments/' + id);
    }

    static async UploadAttachmentToWorkOrder(libraryId: string, workOrderId: string) {
        return await ApiClient.Post('/from-library/', {
            libraryId,
            workOrderId,
        });
    }

    static async Delete(id: string) {
        return await ApiClient.Delete('/attachments/' + id);
    }

    static async Create(
        workOrderId: any,
        assetId: any,
        contractId: any,
        hazardId: any,
        incidentId: any,
        incidentActionId: any,
        proposalId: any,
        name: string,
        file: any,
        quoteId: any,
        reviewId: any,
        isPrivate: boolean,
        documentTypeId?: any,
        expiryDate?: any,
        visibility?: any
    ) {
        let formData = new FormData()
        formData.append('name', name)
        formData.append('file', file)
        formData.append('private', isPrivate ? 'yes' : 'no')

        if (workOrderId) formData.append('workOrderId', workOrderId)
        if (assetId) formData.append('assetId', assetId)
        if (contractId) formData.append('contractId', contractId)
        if (quoteId) formData.append('quoteId', quoteId)
        if (reviewId) formData.append('reviewId', reviewId)
        if (documentTypeId) formData.append('documentTypeId', documentTypeId)
        if (expiryDate) formData.append('expiryDate', expiryDate)
        if (visibility) formData.append('visibility', visibility)
        if (hazardId) formData.append('hazardId', hazardId)
        if (incidentId) formData.append('incidentId', incidentId)
        if (incidentActionId) formData.append('incidentActionId', incidentActionId)
        if(proposalId) formData.append('proposalId', proposalId)

        var accessToken = localStorage.getItem('acess-token')

        const response = await fetch((await GetAPIDomain()) + '/attachments', {
            method: 'post',
            headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
            body: formData,
        })

        if (!response.ok) {
            return false
        }

        return await response.json()
    }

    static async CreateStandAlone(
        hazardId: any,
        incidentId: any,
        name: string,
        file: any,
        createdById: string
    ) {
        let formData = new FormData()
        formData.append('name', name)
        formData.append('file', file)
        formData.append('createdById', createdById)
        if (hazardId) formData.append('hazardId', hazardId)
        if (incidentId) formData.append('incidentId', incidentId)

        const response = await fetch((await GetAPIDomain()) + '/attachments/stand-alone', {
            method: 'post',
            // headers: new Headers({Authorization: 'Bearer ' + accessToken}),
            body: formData,
        })

        if (!response.ok) {
            return false
        }

        return await response.json()
    }

    static async Update(
        id: string,
        name: string,
        isPrivate: boolean,
        documentTypeId?: any,
        expiryDate?: any,
        visibility?: any
    ) {


        return await ApiClient.Patch('/attachments/' + id, [
            {
                op: 'replace',
                path: 'name',
                value: name,
            },
            {
                op: 'replace',
                path: 'documentTypeId',
                value: documentTypeId,
            },
            {
                op: 'replace',
                path: 'expiryDate',
                value: expiryDate,
            },
            {
                op: 'replace',
                path: 'visibility',
                value: visibility,
            },
            {
                op: 'replace',
                path: 'private',
                value: isPrivate,
            },
        ]);
    }
}
