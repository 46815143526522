import { Action } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export interface ActionWithPayload<T> extends Action {
    payload?: T
}

export const actionTypes = {
    SetFilter: '[Opportunity SetFilter] Action',
    ResetFilter: '[Opportunity ResetFilter] Action',
}

const initialOpportunitiesState: IOpportunitiesState = {
    filter: {
        projectType: '',
        projectManagerId: '',
        salesPersonId: '',
        customerId: '',
        likelihood: '',
        status: '',
        pagination: {
            currentPage: 1,
            totalRecords: 1,
            totalPages: 1,
            skip: 0,
            take: 50,
            reset: true,
            sort: 'name',
            direction: 'asc',
        },
    },
}

export interface IOpportunitiesState {
    filter?: {
        projectType: string
        projectManagerId: string
        salesPersonId: string
        customerId: string
        likelihood: string
        status: string
        pagination: any
    }
}

export const reducer = persistReducer(
    { storage, key: 'v100-Opportunity', whitelist: ['filter'] },
    (state: IOpportunitiesState = initialOpportunitiesState, action: ActionWithPayload<IOpportunitiesState>) => {
        switch (action.type) {
            case actionTypes.ResetFilter: {
                return initialOpportunitiesState
            }

            case actionTypes.SetFilter: {

                console.log(action.payload?.filter);

                const filter = action.payload?.filter
                return { ...state, filter }
            }

            default:
                return state
        }
    }
)

export const Actions = {
    resetFilter: () => ({ type: actionTypes.ResetFilter }),
    setFilter: (filter: object) => ({
        type: actionTypes.SetFilter,
        payload: { filter },
    }),
}

export function* saga() { }