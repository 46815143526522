import React, {useEffect, useState} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import Select from 'react-select'
import {RootState} from '../../../../setup'
import {TenantsClient, TenantsQueryParams} from '../../../infrastracture/api/TenantsClient'
import {TenantTypeIds} from '../../constants/tenantTypeIds'
import customStyles from './customStyles'

interface Props {
  tenantTypeIds?: string[]
  name?: string
  defaultValue?: any
  placeholder?: string
  disabled?: boolean
  onChange?: (
    name: any,
    value: any,
    label: any,
    operationalClient: any,
    departments?: any,
    externalProjectId?: any,
    suppliedPurchaseOrder?: any
  ) => void
  id?: string
  removeClients?: boolean
  removeContractors?: boolean
  removeOCContractors?: boolean
  includeAll?: boolean
  clientWOUpdateUserId?: string
  populateManagers?: (managers: any) => void
}

const Tenantselector: React.FC<Props> = (props: any) => {
  const {
    id,
    defaultValue,
    placeholder,
    disabled,
    name,
    removeClients,
    includeAll,
    removeContractors,
    tenantTypeIds,
    removeOCContractors,
    clientWOUpdateUserId,
    populateManagers,
  } = props
  const [options, setOptions] = useState<any[]>([])
  const [value, setValue] = useState<any>({})
  const user: any = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as any

  const defaultId = id || 'tenantId'

  useEffect(() => {
    populateData()
  }, [])

  useEffect(() => {
    if (defaultValue && defaultValue?.label) {
      setValue(defaultValue)
    } else if (defaultValue && options) {
      var selected = options.find((x: any) => x.value == defaultValue.value)
      setValue(selected)
    } else if (value != defaultValue) setValue(defaultValue)
  }, [options, defaultValue])

  useEffect(() => {
    if (value != defaultValue) setValue(defaultValue)
  }, [defaultValue])

  const populateData = async () => {
    var query = new TenantsQueryParams()

    if (clientWOUpdateUserId) query.WithClientWOUpdateUserId(clientWOUpdateUserId)

    if (removeClients) query.WithRemoveClients(true)
    if (removeContractors) query.WithRemoveContractors(true)
    if (removeOCContractors) query.WithRemoveOCContractors(true)
    if (tenantTypeIds && tenantTypeIds.length > 0) query.WithTenantTypeIds(tenantTypeIds)

    var data = (
      await TenantsClient.List(
        query
          .Sort('name', 'asc')
          .Paginate(0, 10000)
          .WithEnabled(true)
          .WithPopulateTenantMappings(false)
      )
    ).data

    if (data) {
      if (includeAll) {
        const opt = data.data.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
            operationalClient: item.operationalClient,
            departments: item.departments,
            externalProjectId: item.externalProjectId,
            suppliedPurchaseOrder: item.suppliedPurchaseOrder,
          }
        })

        setOptions([{value: 'all', label: 'All'}, ...opt])
      } else {
        setOptions(
          data.data.map((item: any) => {
            return {
              value: item.id,
              label: item.name,
              operationalClient: item.operationalClient,
              departments: item.departments,
              externalProjectId: item.externalProjectId,
              suppliedPurchaseOrder: item.suppliedPurchaseOrder,
            }
          })
        )
      }

      if (data.data?.length === 1) {
        handleChange({
          value: data.data[0].id,
          label: data.data[0].name,
          operationalClient: data.data[0].operationalClient,
          departments: data.data[0]?.departments,
          externalProjectId: data.data[0]?.externalProjectId,
          suppliedPurchaseOrder: data.data[0].suppliedPurchaseOrder,
        })
      }
    }
  }

  const handleChange = async (values: any) => {
    const {onChange} = props
    const {label, value, operationalClient, departments, externalProjectId, suppliedPurchaseOrder} =
      values
    setValue(values)
    onChange(
      defaultId,
      value,
      label,
      operationalClient,
      departments,
      externalProjectId,
      suppliedPurchaseOrder
    )

    if (value === 'all') return

    var managers = await TenantsClient.GetManagers(value)

    if (populateManagers) {
      if (managers.data && managers.data.length > 0) populateManagers(managers.data)
      else populateManagers([])
    }
  }

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      id={name ? name : defaultId}
      name={name ? name : defaultId}
      styles={customStyles}
      menuPortalTarget={document.body}
    />
  )
}

export default Tenantselector
