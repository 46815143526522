import axios from 'axios'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { GetAPIDomain, RefreshToken } from '../../app/modules/auth/redux/AuthCRUD'
import { ApiResponse } from '../../app/infrastracture/ApiClient';

export class ApiClient {

    static async Delete(path: string, data?: any) {
        var domain = (await GetAPIDomain());
        return ApiResponse.Create(await Create().delete(domain + path, { data: data }));
    }

    static async Get(path: string) {
        var domain = (await GetAPIDomain());
        return ApiResponse.Create(await Create().get(domain + path));
    }

    static async Post(path: string, data?: any) {
        var domain = (await GetAPIDomain());
        return ApiResponse.Create(await Create().post(domain + path, data));
    }

    static async Patch(path: string, data: any) {
        var domain = (await GetAPIDomain());
        return ApiResponse.Create(await CreateWithContentType("application/json-patch+json").patch(domain + path, data));
    }

}

export class PublicApiClient {

    static async Get(path: string) {
        var domain = (await GetAPIDomain());
        return ApiResponse.Create(await PublicCreate().get(domain + path));
    }

    static async Post(path: string, data?: any) {
        var domain = (await GetAPIDomain());
        return ApiResponse.Create(await PublicCreate().post(domain + path, data));
    }

}

function PublicCreate() {

    var client = axios.create({
        headers: {
            Accept: 'application/json, application/xml, text/play, text/html, *.*',
            'Content-Type': 'application/json',
        }
    });

    return client;

}

function CreateWithContentType(contentType: string) {
    var client = axios.create({
        headers: {
            Accept: 'application/json, application/xml, text/play, text/html, *.*',
            'Content-Type': contentType,
        }
    });

    client.interceptors.request.use(
        (config) => {
            if (localStorage.getItem('acess-token')) {
                config.headers.Authorization = 'Bearer ' + localStorage.getItem("acess-token")
            }
            return config
        },
        (error) => {
            return Promise.reject(error)
        }
    );

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(client, refreshAuthLogic, {
        statusCodes: [401],
    });

    return client;
}

function Create() {
    return CreateWithContentType('application/json');
}

const refreshAuthLogic = async (failedRequest: any) => {
    var refresh = await RefreshToken()

    if (refresh) return Promise.resolve()

    return Promise.reject()
}
